import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import { slinkyApi } from "@anthology/shared/src/api/slinkyApi";
import { authSlice } from "@anthology/shared/src/features";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  [slinkyApi.reducerPath]: slinkyApi.reducer,
  auth: authSlice,
});

//const persistSlices = isDev ? ['auth'] : ['auth', 'theme', 'config'];
const persistSlices = ["auth", "theme"];
//const persistSlices = ['auth'];

const persistConfig = {
  key: "root",
  storage,
  // version: environment.autoSourceHash as any,
  whitelist: persistSlices,
  migrate: (state) => {
    //   if ((currentVersion as any) !== environment.autoSourceHash) {
    //     diagnosticsService.warn(
    //       `Version update ${currentVersion} to ${environment.autoSourceHash} - Clearing state`
    //     );
    //     return Promise.resolve({} as any);
    //   }
    return Promise.resolve(state);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as PersistConfig<any>;

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer) as typeof rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(slinkyApi.middleware);
  },
  // devTools: environment.enableReduxDevTools && {
  //   maxAge: 200,
  //   trace: false,
  //   // actionsDenylist: 'api/.*'
  // },
});

export type RootState = ReturnType<typeof store.getState>;
export const storePersistor = persistStore(store);
