import { createTheme } from "@mui/material/styles";
// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from "@mui/material/styles/createTypography";
import "./typography";

export const breakpoints = {
  values: {
    tablet: 1024,
  },
};

export function createTypography(font: string = "Roboto", scale: number = 1.0) {
  // Recommended font weights
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight
  const fontWeights = {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  };

  const fontFamily = [font, "Roboto", "sans-serif"].join(",");

  const { pxToRem: defaultpxToRem } = createTheme().typography;

  const pxToRem = (x: number) => defaultpxToRem(x * scale);

  const typography = {
    fontFamily,
  } as TypographyOptions;

  typography.h1 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(51),
    lineHeight: "114%",
    letterSpacing: pxToRem(-0.3),
  };

  typography.sub1 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(58),
    letterSpacing: pxToRem(0),
  };

  typography.h2 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(41),
    letterSpacing: pxToRem(-0.3),
  };

  typography.sub2 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(26),
    lineHeight: "130%",
    letterSpacing: pxToRem(0),
  };

  typography.h3 = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(25),
    lineHeight: pxToRem(30),
    letterSpacing: pxToRem(0),
  };

  typography.sub3 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(20),
    lineHeight: "130%",
    letterSpacing: pxToRem(0),
  };

  typography.h4 = {
    fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.15),
  };

  typography.sub4 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(16),
    lineHeight: "120%",
    letterSpacing: pxToRem(0),
  };

  typography.h5 = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(23),
    letterSpacing: pxToRem(0.15),
  };

  typography.sub5 = {
    fontFamily,
    fontWeight: fontWeights.light,
    fontSize: pxToRem(16),
    lineHeight: "120%",
    letterSpacing: pxToRem(0),
  };

  typography.labelLarge = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(23),
    letterSpacing: pxToRem(0.2),
  };

  typography.labelMedium = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(13),
    lineHeight: "177%",
    letterSpacing: pxToRem(0.1),
  };

  typography.labelSmall = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(9),
    lineHeight: "130%",
    letterSpacing: pxToRem(0),
  };

  typography.bodyLarge = {
    fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(23),
    letterSpacing: pxToRem(0.15),
  };

  typography.bodyMedium = {
    fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.2),
  };

  typography.linkTextLarge = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(23),
    letterSpacing: pxToRem(0.15),
    color: "primary.light",
  };

  typography.linkTextMedium = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.2),
    color: "primary.light",
  };

  typography.caption = {
    fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(10),
    letterSpacing: pxToRem(0.4),
  };

  typography.captionSmall = {
    fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
    letterSpacing: pxToRem(0.4),
  };

  typography.button = {
    fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(16),
    lineHeight: "100%",
    letterSpacing: pxToRem(0.1),
    textTransform: "none",
  };

  //name alias according to Figma
  typography.displayLarge = typography.h1; //alias
  typography.displaySmall = typography.h2; //alias
  typography.headline = typography.h3; //alias
  typography.title = typography.h4; //alias

  // silently alias MUI types so built in components pick up fonts, these do not appear in the Type of theme
  typography.subtitle1 = typography.sub4 as any; // mui alias
  typography.subtitle2 = typography.sub5 as any; // mui alias

  typography.body1 = typography.bodyLarge as any; // mui alias
  typography.body2 = typography.bodyMedium as any; // mui alias
  typography.h6 = typography.h5 as any; // mui alias
  typography.overline = typography.button as any;

  return typography;
}
