import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type LoginResponseViewModel = {
  token?: string | null;
  refreshToken?: string | null;
  expiresIn?: number;
  user?: UserViewModel;
};

type UserViewModel = {
  userId?: number;
  tenantId?: number;
  username?: string | null;
  email?: string | null;
  firstname?: string | null;
  lastName?: string | null;
  mobile?: string | null;
  mobilePreferred?: boolean;
  userPermissions?: UserPermissionSummaryModal;
};

type UspUserPermissionsGetServicePermissionsForUserResult = {
  inferredClientId?: number | null;
  inferredParentLabelId?: number | null;
  inferredLabelId?: number | null;
  inferredContributorId?: number | null;
  inferredPublisherGroupId?: number | null;
  servicePermissionLinkId?: number;
  accessLevelCode?: number | null;
  permissionLevelRank?: number | null;
};

type UserPermissionSummaryModal = {
  tenantId?: number;
  roles?: string[] | null;
  accessTiers?: string[] | null;
  maxServices?: string[] | null;
  accessLevelRank?: number;
  allPermissions?:
    | UspUserPermissionsGetServicePermissionsForUserResult[]
    | null;
};

type AuthState = {
  user?: LoginResponseViewModel;
  isSignedIn: boolean;
  isError: boolean;
  expiresAt: string;
};

const init: AuthState = {
  user: undefined,
  isSignedIn: false,
  isError: false,
  expiresAt: new Date(1900, 1, 1).toISOString(),
};

const slice = createSlice({
  name: "auth",
  initialState: init,
  reducers: {
    authSetCredentials: (
      state,
      { payload }: PayloadAction<LoginResponseViewModel>
    ) => {
      if (payload.token) {
        const exp = new Date();
        exp.setSeconds(exp.getSeconds() + payload.expiresIn!);
        state.user = payload;
        state.isSignedIn = true;
        state.isError = false;
        state.expiresAt = exp.toISOString();
      } else {
        throw new Error("Reducer was passed invalid user model");
      }
    },
    authSignOut: (state) => {
      state.user = undefined;
      state.isSignedIn = false;
      state.isError = false;
    },
    authIsError: (state, { payload }: PayloadAction<boolean>) => {
      state.isError = payload;
    },
  },
});

export const { authSetCredentials, authSignOut } = slice.actions;

export default slice.reducer;
