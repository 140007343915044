const anthologyLiveUrl = "https://anthology.to";
const defaultBaseUrl =
  typeof window !== "undefined"
    ? window.location.host.indexOf("localhost") > -1
      ? "https://dev.anthology.to"
      : window.location.host.indexOf("slinky") > -1
        ? anthologyLiveUrl
        : ""
    : anthologyLiveUrl;
const CDNRoot = "https://cdn.anthology.to";
const staticPath = `${CDNRoot}/static/`;
const defaultErrorImage = `${CDNRoot}/static/missing.png`;
const generalPath = `${CDNRoot}/static/general/`;
const logosPath = `${CDNRoot}/static/logos/`;
const avatarBlankDark = `${generalPath}avatar-blank-dark-mode.png`;
const avatarBlankLight = `${generalPath}avatar-blank.png`;
const flagPath = `${staticPath}flags/`;
const unknownImage = `${flagPath}circle/xx.svg`;
const slinkyIconUrl = `${logosPath}slinky-icon.png`;
const slinkyBaseUrl = "https://slinky.to/";
const slinkyDestinationFallBackImg = `${staticPath}slinky/slinky_destination_fallback.png`;
const mockImagesUrl = `${CDNRoot}/mock_images/`;
const privacyUrl = "https://absolutelabelservices.com/privacy-and-cookies/";

export {
  CDNRoot,
  avatarBlankDark,
  avatarBlankLight,
  defaultBaseUrl,
  defaultErrorImage,
  flagPath,
  generalPath,
  logosPath,
  mockImagesUrl,
  privacyUrl,
  slinkyBaseUrl,
  slinkyDestinationFallBackImg,
  slinkyIconUrl,
  staticPath,
  unknownImage,
};
