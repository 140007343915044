import { defaultTheme } from '@anthology/shared/src/constants/theme';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { store } from '../features/store';
import './globals.css';


const App = ({ Component, pageProps }: AppProps) => {
  const theme = extendTheme({
    ...defaultTheme,
  });
  return (
    <>

      <Provider store={store}>
        <CssVarsProvider theme={theme} defaultMode="dark">
          <CssBaseline />
          <Component {...pageProps} />
        </CssVarsProvider>
      </Provider>
    </>
  );
};

export default App;
